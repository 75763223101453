//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useNewsletter, useCategory } from '@odyssey/realtainment';
import { ref, computed, useContext } from '@nuxtjs/composition-api';
import useUiNotification from '~/composables/useUiNotification';
const BASE_CLASS = 'newsletter';

export default {
  name: 'SNewsLetterBanner',
  components: {
    NewsletterChild: () =>
      import(/* webpackChunkName: "chunk-ui-organisms" */ '@/components/UI/organisms/newsletter/NewsletterChild')
  },
  props: {
    cssFlags: {
      type: Array,
      required: false,
      default: () => []
    },
    title: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#f1e8ff'
    },
    hideImage: {
      type: Boolean,
      required: false,
      default: false
    },
    tagContext: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props) {
    const newsletter = ref(null);
    const { send: showNotification } = useUiNotification();
    const { subscribe, validate, error, stateErrors, isSubscribed, loading: isSending } = useNewsletter();
    const { categories } = useCategory();
    const { $recaptcha, i18n, route } = useContext();

    const containerClasses = computed(() => [BASE_CLASS, ...props.cssFlags.map((f) => `${BASE_CLASS}--${f}`)]);

    const onSubmit = async (form) => {
      await $recaptcha.init();

      if (await validate(form, props.tagContext)) {
        form.token = await $recaptcha.execute('login');
        form.brand = 'artnight';
        form.location = route.value.name.split('__')[0];
        form.campaign = route.value.path;
        await subscribe(form, props.tagContext);
        if (error.value === null) {
          showNotification({
            type: 'success',
            message: i18n.t('newsletter.artnight.subscribed_text')
          });
        }
      }
    };

    const sortedCities = computed(() => categories.value.filter((category) => category.includeInMenu));
    return {
      newsletter,
      stateErrors,
      containerClasses,
      isSending,
      isSubscribed,
      onSubmit,
      sortedCities
    };
  }
};
