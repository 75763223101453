//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { reactive, onMounted } from '@nuxtjs/composition-api';

export default {
  name: 'NewsletterChild',
  components: {
    Input: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/Input'),
    SButton: () => import(/* webpackChunkName: "chunk-ui-molecules-buttons" */ '@/components/UI/molecules/buttons/SButton'),
    Dropdown: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/Dropdown')
  },
  props: {
    selectedCity: {
      type: Object,
      required: false,
      default: null
    },
    showCitySelect: {
      type: Boolean,
      required: false,
      default: false
    },
    cityOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    emailValidations: {
      type: Array,
      required: false,
      default: () => []
    },
    cityValidations: {
      type: Array,
      required: false,
      default: () => []
    },
    isSubscribed: {
      type: Boolean,
      required: false,
      default: false
    },
    isSending: {
      type: Boolean,
      required: false,
      default: false
    },
    submitText: {
      type: String,
      required: false,
      default: ''
    },
    sentText: {
      type: String,
      required: false,
      default: ''
    },
    emailPlaceholder: {
      type: String,
      required: false,
      default: ''
    },
    cityPlaceholder: {
      type: String,
      required: false,
      default: ''
    },
    tagContext: {
      type: String,
      required: false,
      default: ''
    },
    buttonShadow: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonFullWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const form = reactive({
      city_id: null,
      city_name: null,
      email_address: null,
      tag_context: props.tagContext
    });
    const onCitySelected = (city) => {
      form.city_id = city.id;
      form.city_name = city.name;
      emit('input', 'city_id', form.city_id);
    };

    onMounted(() => {
      if (props.selectedCity) {
        form.city_id = props.selectedCity.slug;
        form.city_name = props.selectedCity.name;
      }
    });

    return {
      form,
      onCitySelected
    };
  }
};
