var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.containerClasses,style:({ backgroundColor: _vm.backgroundColor })},[_c('div',{staticClass:"newsletter__container container"},[(!_vm.hideImage)?_c('div',{staticClass:"newsletter__media"},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:('/assets/artnight/bgs/newsletter--home.png'),expression:"'/assets/artnight/bgs/newsletter--home.png'",arg:"background-image"}],staticClass:"newsletter__media__bg"})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"newsletter__content"},[(_vm.title)?_c('h2',{staticClass:"newsletter__title"},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.description)?_c('p',{staticClass:"newsletter__text",domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e(),_vm._v(" "),_c('newsletter-child',_vm._b({ref:"newsletter",staticClass:"newsletter__input-fields",attrs:{"city-options":_vm.sortedCities,"is-sending":_vm.isSending,"is-subscribed":_vm.isSubscribed,"submitText":_vm.$t('newsletter.button_send_text'),"sentText":_vm.$t('newsletter.button_sent_text'),"emailPlaceholder":_vm.$t('newsletter.placeholder_email'),"cityPlaceholder":_vm.$t('newsletter.placeholder_city'),"email-validations":[
          {
            condition: _vm.stateErrors.email_address && !_vm.stateErrors.email_address.required,
            text: _vm.$t('newsletter.validations.required')
          },
          {
            condition: _vm.stateErrors.email_address && !_vm.stateErrors.email_address.valid,
            text: _vm.$t('newsletter.validations.email')
          }
        ],"city-validations":[
          {
            condition: _vm.stateErrors.city_id && !_vm.stateErrors.city_id.valid,
            text: _vm.$t('newsletter.validations.city_required')
          }
        ]},on:{"submit":_vm.onSubmit}},'newsletter-child',_vm.$attrs,false))],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }