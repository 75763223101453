//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
import { useStoryblokGlobalComponents } from '@odyssey/realtainment';
import useUiState from '@/composables/useUiState';
export default {
  components: {
    SocialBar: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/SocialBar'),
    SearchWidget: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/SearchWidget')
  },
  setup() {
    const { globalComponents } = useStoryblokGlobalComponents();
    const { socialLinks } = useUiState();

    const legalLinks = [
      {
        name: 'AGB',
        to: '/agb/'
      },
      {
        name: 'Datenschutz',
        to: '/datenschutz/'
      },
      {
        name: 'Impressum',
        to: '/impressum/'
      }
    ];
    const footerData = computed(() => globalComponents.value.footer);

    const currentYear = new Date().getFullYear();

    return {
      footerData,
      globalComponents,
      legalLinks,
      socialLinks,
      currentYear
    };
  }
};
